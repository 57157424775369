import React from 'react';
import styled from 'styled-components';

import { almostWhite, black, primary } from 'styles/colors';
import {
  Container,
  FlexWrapper,
  H1,
  Header,
  PrimaryButton,
  Seo,
  Svg,
  TextWrapper,
} from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import { images } from 'utils/images';
import { useRouter } from 'apis/history';
import loadable from '@loadable/component';

const FooterDefault = loadable(() => import('layouts/footers/FooterDefault'), {
  ssr: true,
});

const NotFound = () => {
  const { isMobile } = useQuery();
  const { goToLanding } = useRouter();

  return (
    <>
      <Seo title="Not found | Pulsio" />
      <Background>
        <Container>
          <Header />
          <TextWrapper
            textAlign="center"
            margin="0 auto"
            padding={isMobile ? '3rem 0' : '0'}
            maxWidth="51.5rem"
            height="55vh"
          >
            <FlexWrapper
              justifyContent="center"
              margin={isMobile ? '1.5rem 0 0.5rem' : '5.125rem 0 0.625rem'}
            >
              <Svg
                src="icons/leaves_2"
                width="52"
                height="36"
                viewBox="0 0 52 36"
                fill="none"
                style={{
                  display: 'block',
                }}
              />
            </FlexWrapper>
            <H1 color={black} margin="0 auto">
              Page not found.
            </H1>
            <H1 color={primary} margin="0 auto">
              404
            </H1>
            <FlexWrapper justifyContent="center" margin="3rem 0 0">
              <PrimaryButton
                maxWidth={isMobile ? '100%' : ''}
                onClick={() => goToLanding()}
              >
                HOME
              </PrimaryButton>
            </FlexWrapper>
          </TextWrapper>
        </Container>
        <Line />
        <FooterDefault />
      </Background>
    </>
  );
};

export default NotFound;

const Background = styled.div<{ minHeight?: string }>`
  position: relative;
  min-height: ${props => props.minHeight || '100vh'};
  background: ${almostWhite};
`;

const Line = styled.div`
  width: 100%;
  height: 133px;
  background-image: url(${images['line404_dekstop']});
  background-position: 0% 48%;
  background-size: 100%;
  margin: 8rem 0;
  @media ${mobile} {
    margin: 5rem 0;
    height: 75px;
    background-image: url(${images['line404_mobile']});
    background-position: 0% 39%;
    background-size: 100%;
  }
`;
